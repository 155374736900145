
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'nickname';
    
    this.auth_attendance = false;
    this.auth_payment = false;
    this.auth_nencho = false;
  }
}
