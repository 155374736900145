
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import LItemlevel from "@/components/l/Itemlevel.vue";
import EmployeeeachLaborPaymentBasewage from "@/components/employeeeach/labor/payment/Basewage.vue";
import EmployeeeachLaborPaymentTraininghourlywage from "@/components/employeeeach/labor/payment/Traininghourlywage.vue";
import EmployeeeachLaborPaymentBasepay from "@/components/employeeeach/labor/payment/Basepay.vue";
import EmployeeeachLaborPaymentIndividualunit from "@/components/employeeeach/labor/payment/Individualunit.vue";
import EmployeeeachLaborPaymentCarfare from "@/components/employeeeach/labor/payment/Carfare.vue";
import EmployeeeachLaborPaymentOvertype from "@/components/employeeeach/labor/payment/Overtype.vue";
import EmployeeeachLaborPaymentIsmid from "@/components/employeeeach/labor/payment/Ismid.vue";
import EmployeeeachLaborPaymentIshol from "@/components/employeeeach/labor/payment/Ishol.vue";
import EmployeeeachLaborPaymentUsepaidholtype from "@/components/employeeeach/labor/payment/Usepaidholtype.vue";
import EmployeeeachLaborPaymentIsbonus from "@/components/employeeeach/labor/payment/Isbonus.vue";

@Component({
  components: {
    LItemlevel,
    EmployeeeachLaborPaymentBasewage,
    EmployeeeachLaborPaymentTraininghourlywage,
    EmployeeeachLaborPaymentBasepay,
    EmployeeeachLaborPaymentIndividualunit,
    EmployeeeachLaborPaymentCarfare,
    EmployeeeachLaborPaymentOvertype,
    EmployeeeachLaborPaymentIsmid,
    EmployeeeachLaborPaymentIshol,
    EmployeeeachLaborPaymentUsepaidholtype,
    EmployeeeachLaborPaymentIsbonus,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;
  @Prop() global_config: any;
  @Prop() individual_units: any;

  get raw_object() {
    return this.employee;
  }
  get tmp_object() {
    return this.manager.object.employee;
  }

}
