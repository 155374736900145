
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import LItemlevel from "@/components/l/Itemlevel.vue";
import EmployeeeachLaborTaxinsIsincometax from "@/components/employeeeach/labor/taxins/Isincometax.vue";
import EmployeeeachLaborTaxinsIncometaxcomment from "@/components/employeeeach/labor/taxins/Incometaxcomment.vue";
import EmployeeeachLaborTaxinsRestax1 from "@/components/employeeeach/labor/taxins/Restax1.vue";
import EmployeeeachLaborTaxinsRestax2 from "@/components/employeeeach/labor/taxins/Restax2.vue";
import EmployeeeachLaborTaxinsHealthcareins from "@/components/employeeeach/labor/taxins/Healthcareins.vue";
import EmployeeeachLaborTaxinsPensionins from "@/components/employeeeach/labor/taxins/Pensionins.vue";
import EmployeeeachLaborTaxinsChildcare from "@/components/employeeeach/labor/taxins/Childcare.vue";
import EmployeeeachLaborTaxinsStdincome from "@/components/employeeeach/labor/taxins/Stdincome.vue";
import EmployeeeachLaborTaxinsDoublebiz from "@/components/employeeeach/labor/taxins/Doublebiz.vue";
import EmployeeeachLaborTaxinsPensioninsid from "@/components/employeeeach/labor/taxins/Pensioninsid.vue";
import EmployeeeachLaborTaxinsSocialinsid from "@/components/employeeeach/labor/taxins/Socialinsid.vue";
import EmployeeeachLaborTaxinsSocialinsgoton from "@/components/employeeeach/labor/taxins/Socialinsgoton.vue";
import EmployeeeachLaborTaxinsEmpins from "@/components/employeeeach/labor/taxins/Empins.vue";
import EmployeeeachLaborTaxinsEmpinsid from "@/components/employeeeach/labor/taxins/Empinsid.vue";
import EmployeeeachLaborTaxinsEmpinsgoton from "@/components/employeeeach/labor/taxins/Empinsgoton.vue";
import EmployeeeachLaborTaxinsCompins from "@/components/employeeeach/labor/taxins/Compins.vue";
import EmployeeeachLaborTaxinsCompinsgoton from "@/components/employeeeach/labor/taxins/Compinsgoton.vue";

@Component({
  components: {
    LItemlevel,
    EmployeeeachLaborTaxinsIsincometax,
    EmployeeeachLaborTaxinsIncometaxcomment,
    EmployeeeachLaborTaxinsRestax1,
    EmployeeeachLaborTaxinsRestax2,
    EmployeeeachLaborTaxinsHealthcareins,
    EmployeeeachLaborTaxinsPensionins,
    EmployeeeachLaborTaxinsChildcare,
    EmployeeeachLaborTaxinsStdincome,
    EmployeeeachLaborTaxinsDoublebiz,
    EmployeeeachLaborTaxinsPensioninsid,
    EmployeeeachLaborTaxinsSocialinsid,
    EmployeeeachLaborTaxinsSocialinsgoton,
    EmployeeeachLaborTaxinsEmpins,
    EmployeeeachLaborTaxinsEmpinsid,
    EmployeeeachLaborTaxinsEmpinsgoton,
    EmployeeeachLaborTaxinsCompins,
    EmployeeeachLaborTaxinsCompinsgoton,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;
  @Prop() global_config: any;
  @Prop() health_ins_stdincomes: any;

  get raw_object() {
    return this.employee;
  }
  get tmp_object() {
    return this.manager.object.employee;
  }

}
