
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import EmployeeeachSelfBaseName from "@/components/employeeeach/self/base/Name.vue";
import EmployeeeachSelfBaseCode from "@/components/employeeeach/self/base/Code.vue";
import EmployeeeachSelfBaseAuth from "@/components/employeeeach/self/base/Auth.vue";
import EmployeeeachSelfBaseHiringtype from "@/components/employeeeach/self/base/Hiringtype.vue";
import EmployeeeachSelfBaseMainbranch from "@/components/employeeeach/self/base/Mainbranch.vue";
import EmployeeeachSelfBasePost from "@/components/employeeeach/self/base/Post.vue";
import EmployeeeachSelfBaseEmployeetype from "@/components/employeeeach/self/base/Employeetype.vue";

@Component({
  components: {
    EmployeeeachSelfBaseName,
    EmployeeeachSelfBaseCode,
    EmployeeeachSelfBaseAuth,
    EmployeeeachSelfBaseHiringtype,
    EmployeeeachSelfBaseMainbranch,
    EmployeeeachSelfBasePost,
    EmployeeeachSelfBaseEmployeetype,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;
  @Prop() branch_hash: any;
  @Prop() global_config: any;

  get raw_object() {
    return this.employee;
  }
  get tmp_object() {
    return this.manager.object.employee;
  }


}
