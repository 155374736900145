import { Component, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import vuex_data from '@/vuex/vuex_data';
import vuex_manager from '@/vuex/vuex_manager';

@Component
export default class mixinEmployeeAdd extends Mixins(utilMixins) {
  get m() {
    return vuex_manager.employee_add_manager;
  }
  get global() {
    return vuex_data.employee_each_global.get(this);
  }

  get object() {
    return this.m.edit_manager.add.object.employee;
  }

  get employee_type_auth() {
    const type_array = [
      {shift: true, attendance: true, payment: true, nencho: true},
      {shift: true, attendance: false, payment: false, nencho: false},
      {shift: true, attendance: true, payment: false, nencho: false},
      {shift: false, attendance: false, payment: true, nencho: false},
      {shift: false, attendance: false, payment: true, nencho: true},
      {shift: false, attendance: false, payment: false, nencho: true},
    ];
    return type_array[this.object.type];
  }

  get menu_options() {
    var options = {};

    options['self'] = {name: '本人情報', route: 'self_base', pages: ['self_base', 'self_general', 'self_bank', 'self_tax']};
    options['labor'] = {name: '労務情報', route: 'labor_general', pages: ['labor_general', 'labor_payment', 'labor_taxins']};
    
    return options;
  }

  get menu_sub_options() {
    var options = {};

    options['self'] = []
    options['self'].push({name: '基本情報', route: 'self_base', pages: ['self_base']});
    options['self'].push({name: '本人情報全般', route: 'self_general', pages: ['self_general']});
    if (this.employee_type_auth.payment) options['self'].push({name: '振込口座', route: 'self_bank', pages: ['self_bank']});
    if (this.employee_type_auth.payment || this.employee_type_auth.nencho) options['self'].push({name: '税額表区分（甲乙）', route: 'self_tax', pages: ['self_tax']});

    options['labor'] = [];
    options['labor'].push({name: '労務情報全般', route: 'labor_general', pages: ['labor_general']});
    if (this.employee_type_auth.attendance || this.employee_type_auth.payment) options['labor'].push({name: '給与設定', route: 'labor_payment', pages: ['labor_payment']});
    if (this.employee_type_auth.payment) options['labor'].push({name: '税・社会保険設定', route: 'labor_taxins', pages: ['labor_taxins']});

    return options;
  }
}
