
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Inject } from '@/library/provide';
import LItemlevel from "@/components/l/Itemlevel.vue";
import EmployeeeachLaborGeneralWorktype from "@/components/employeeeach/labor/general/Worktype.vue";
import EmployeeeachLaborGeneralCoretime from "@/components/employeeeach/labor/general/Coretime.vue";
import EmployeeeachLaborGeneralWeekprescribedday from "@/components/employeeeach/labor/general/Weekprescribedday.vue";
import EmployeeeachLaborGeneralWeekprescribedtime from "@/components/employeeeach/labor/general/Weekprescribedtime.vue";
import EmployeeeachLaborGeneralMonthprescribedday from "@/components/employeeeach/labor/general/Monthprescribedday.vue";
import EmployeeeachLaborGeneralMonthprescribedtime from "@/components/employeeeach/labor/general/Monthprescribedtime.vue";
import EmployeeeachLaborGeneralJoinedon from "@/components/employeeeach/labor/general/Joinedon.vue";
import EmployeeeachLaborGeneralRetiredon from "@/components/employeeeach/labor/general/Retiredon.vue";
import EmployeeeachLaborGeneralContractstartedon from "@/components/employeeeach/labor/general/Contractstartedon.vue";
import EmployeeeachLaborGeneralContractendedon from "@/components/employeeeach/labor/general/Contractendedon.vue";
import EmployeeeachLaborGeneralJob from "@/components/employeeeach/labor/general/Job.vue";
import EmployeeeachLaborGeneralMemo from "@/components/employeeeach/labor/general/Memo.vue";

@Component({
  components: {
    LItemlevel,
    EmployeeeachLaborGeneralWorktype,
    EmployeeeachLaborGeneralCoretime,
    EmployeeeachLaborGeneralWeekprescribedday,
    EmployeeeachLaborGeneralWeekprescribedtime,
    EmployeeeachLaborGeneralMonthprescribedday,
    EmployeeeachLaborGeneralMonthprescribedtime,
    EmployeeeachLaborGeneralJoinedon,
    EmployeeeachLaborGeneralRetiredon,
    EmployeeeachLaborGeneralContractstartedon,
    EmployeeeachLaborGeneralContractendedon,
    EmployeeeachLaborGeneralJob,
    EmployeeeachLaborGeneralMemo,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;
  @Prop() global_config: any;

  get raw_object() {
    return this.employee;
  }
  get tmp_object() {
    return this.manager.object.employee;
  }

  @Inject()
  employee_type: number;
}
