
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Inject } from '@/library/provide';
import LItemlevel from "@/components/l/Itemlevel.vue";
import EmployeeeachSelfBankIsbank1 from "@/components/employeeeach/self/bank/Isbank1.vue";
import EmployeeeachSelfBankBankdisp1 from "@/components/employeeeach/self/bank/bankdisp1.vue";
import EmployeeeachSelfBankBankname1 from "@/components/employeeeach/self/bank/bankname1.vue";
import EmployeeeachSelfBankBranchname1 from "@/components/employeeeach/self/bank/branchname1.vue";
import EmployeeeachSelfBankBanktype1 from "@/components/employeeeach/self/bank/banktype1.vue";
import EmployeeeachSelfBankBanknum1 from "@/components/employeeeach/self/bank/banknum1.vue";
import EmployeeeachSelfBankBankholder1 from "@/components/employeeeach/self/bank/bankholder1.vue";
import EmployeeeachSelfBankIsbank2 from "@/components/employeeeach/self/bank/Isbank2.vue";
import EmployeeeachSelfBankBankdisp2 from "@/components/employeeeach/self/bank/bankdisp2.vue";
import EmployeeeachSelfBankBankname2 from "@/components/employeeeach/self/bank/bankname2.vue";
import EmployeeeachSelfBankBranchname2 from "@/components/employeeeach/self/bank/branchname2.vue";
import EmployeeeachSelfBankBanktype2 from "@/components/employeeeach/self/bank/banktype2.vue";
import EmployeeeachSelfBankBanknum2 from "@/components/employeeeach/self/bank/banknum2.vue";
import EmployeeeachSelfBankBankholder2 from "@/components/employeeeach/self/bank/bankholder2.vue";
import EmployeeeachSelfBankBanklimit1 from "@/components/employeeeach/self/bank/banklimit1.vue";

@Component({
  components: {
    LItemlevel,

    EmployeeeachSelfBankIsbank1,
    EmployeeeachSelfBankBankdisp1,
    EmployeeeachSelfBankBankname1,
    EmployeeeachSelfBankBranchname1,
    EmployeeeachSelfBankBanktype1,
    EmployeeeachSelfBankBanknum1,
    EmployeeeachSelfBankBankholder1,

    EmployeeeachSelfBankIsbank2,
    EmployeeeachSelfBankBankdisp2,
    EmployeeeachSelfBankBankname2,
    EmployeeeachSelfBankBranchname2,
    EmployeeeachSelfBankBanktype2,
    EmployeeeachSelfBankBanknum2,
    EmployeeeachSelfBankBankholder2,
    EmployeeeachSelfBankBanklimit1,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;
  @Prop() global_config: any;

  get raw_object() {
    return this.employee;
  }
  get tmp_object() {
    return this.manager.object.employee;
  }

}
