
import { Component, Vue } from 'vue-property-decorator';
import EmployeeaddMain from "@/components/employeeadd/Main.vue";

@Component({
  components: {
    EmployeeaddMain,
  }
})
export default class EmployeeEach extends Vue {
}
