
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FDate from "@/components/f/Date.vue";
import FCheck from "@/components/f/Check.vue";

@Component({
  components: {
    LItem,
    FDate,
    FCheck,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'retired_on';
    this._info = 'info.retired_on';
    this._popup_title = 'retired_on';
    this._popup_content = 'popup.retired_on';
  }
}
