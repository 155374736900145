
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FDate from "@/components/f/Date.vue";

@Component({
  components: {
    LItem,
    FDate,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'contract_ended_on';
    this.auth_shift = false;
    this.auth_attendance = false;
  }
}
