
  import { Component, Mixins, Watch } from "vue-property-decorator";
  import * as util from "@/util";
  import { Provide } from "@/library/provide";
  import mixinEmployeeAdd from "@/mixins/mixinEmployeeAdd";
  import validation from "@/library/validation";
  import dialog from "@/vuex/dialog";
  import LProgress from "@/components/l/Progress.vue";
  import LPage from "@/components/l/Page.vue";
  import LBread from "@/components/l/Bread.vue";
  import LSidemenu from "@/components/l/Sidemenu.vue";
  import FSidemenuscroll from "@/components/f/Sidemenuscroll.vue";
  import LContent from "@/components/l/Content.vue";
  import EmployeeeachSelfBasePack from "@/components/employeeeach/self/base/Pack.vue";
  import EmployeeaddSelfGeneral from "@/components/employeeadd/SelfGeneral.vue";
  import EmployeeeachSelfBankPack from "@/components/employeeeach/self/bank/Pack.vue";
  import EmployeeeachSelfTaxPack from "@/components/employeeeach/self/tax/Pack.vue";
  import EmployeeeachLaborGeneralPack from "@/components/employeeeach/labor/general/Pack.vue";
  import EmployeeaddLaborPayment from "@/components/employeeadd/LaborPayment.vue";
  import EmployeeeachLaborTaxinsPack from "@/components/employeeeach/labor/taxins/Pack.vue";
  import FControl from "@/components/f/Control.vue";

  @Component({
    components: {
      LProgress,
      LPage,
      LBread,
      LSidemenu,
      FSidemenuscroll,
      LContent,
      EmployeeeachSelfBasePack,
      EmployeeaddSelfGeneral,
      EmployeeeachSelfBankPack,
      EmployeeeachSelfTaxPack,
      EmployeeeachLaborGeneralPack,
      EmployeeaddLaborPayment,
      EmployeeeachLaborTaxinsPack,
      FControl,
    },
  })
  export default class Main extends Mixins(mixinEmployeeAdd) {
    created() {
      this.m.initEditManager({ name: "add" });

      if (this.fetched) {
        this.fetchedData(this.fetched);
      }
    }

    get fetched() {
      return !!this.global;
    }

    @Watch("fetched")
    fetchedData(fetched) {
      if (fetched) {
        const addEmployeeObjectCopy = util.deep_copy(this.addEmployeeObject);
        addEmployeeObjectCopy.each_individual_units = {};

        this.global.individual_units.forEach((individual_unit) => {
          addEmployeeObjectCopy.each_individual_units[individual_unit.id] = 0;
        });

        this.m.edit_manager.add.edit({
          employee: addEmployeeObjectCopy,
        });
        this.onChangeHiringType();
      }
    }

    get addEmployeeObject() {
      return {
        auth: 0,
        type: 0,

        name: this.query.add_employee_name,
        kana: this.query.add_employee_kana,
        nickname: null,
        sex: 0,
        birth_date: null,
        age: null, //apiで計算
        mynum: null,
        mail: this.query.add_employee_mail,
        pass: null,
        pass_conf: null,

        //Self
        tax_type: 0,
        dependant_num: null, //apiで計算
        householder_name: null,
        householder_relationship_id: null,
        householder_relationship_custom: null,
        income: null,
        other_income: null,
        disability_type: 0,
        widow_type: 0,
        is_work_student: 0,
        disability_reason: null,
        work_student_reason: null,

        //Address
        zip: null,
        address: null,
        tel: null,
        mobile: null,

        //Bank
        bank1_id: null,
        branch1_id: null,
        branch1_name: null,
        bank1_type: 0,
        bank1_num: null,
        bank1_holder: null,
        bank1_limit: null,
        bank2_id: null,
        branch2_id: null,
        branch2_name: null,
        bank2_type: 0,
        bank2_num: null,
        bank2_holder: null,
        is_bank1: 0,
        is_bank2: 0,

        //dbにはない
        bank1_name: null,
        bank1_code: null,
        branch1_code: null,
        bank2_name: null,
        bank2_code: null,
        branch2_code: null,

        hiring_type: this.query.add_employee_hiring_type,
        post: null,
        job: null,
        code: this.query.add_employee_code,
        branch_id: this.query.add_employee_branch_id,
        week_prescribed_day: null,
        week_prescribed_hour: null,
        month_prescribed_day: null,
        month_prescribed_hour: null,
        month_prescribed_hour_type: 0,
        joined_on: util.today(),
        retired_on: null,
        is_died_retire: false,
        contract_started_on: util.today(),
        contract_ended_on: null,
        cookie: null,
        comment: null,

        //Restax
        yearend_zip: null,
        yearend_address: null,

        //Payment
        route: null,
        wage_type: 0,
        monthly_wage: null,
        basepay_type: 0,
        basepay: null,
        work_type: 0,
        coretime_type: 0,
        coretime_started_at: null,
        coretime_ended_at: null,
        carfare_type: 0,
        is_train: 0,
        is_car: 0,
        carfare: null,
        km: null,
        is_bonus: 0,
        over_type: 0,
        deemed_over_hour: null,
        deemed_over_money: null,
        is_mid: 0,
        is_hol: 0,
        buy_hol_unit: 0,
        buy_paid_hol_unit: 0,
        use_paid_hol_unit: 0,
        training_hourly_wage_type: 0,
        training_hourly_wage_unit: null,
        use_paid_hol_type: 1,
        is_income_tax: 1,
        income_tax_comment: null,
        is_preover: 0,
        is_lawover: 0,
        is_outhol: 0,
        is_lawhol: 0,

        //Tax
        health_ins_type: 0,
        pension_ins_type: 0,
        pension_ins_id: null,
        social_ins_id: null,
        health_ins_seiri_num: null,
        pension_ins_seiri_num: null,
        social_ins_got_on: null,
        emp_ins_type: 0,
        is_comp_ins: 0,
        emp_ins_id: null,
        emp_ins_got_on: null,
        is_child_care: 0,
        child_care_started_on: null,
        child_care_ended_on: null,
        std_income: null,
        is_double_biz: 0,
        std_income_rate: null,
        union_health_name: null,
        union_health_money: 0,
        union_care_money: 0,
        comp_ins_got_on: null,

        //manyのもの
        resident_taxes: {},
        hourly_wages: [],
        training_hourly_wages: [],
        resident_tax_years: {},
        carfares: {},
      };
    }

    onChangeEmployeeType(newval) {
      if (newval == 1) {
        //シフトのみに変わった
        this.object.birth_date = this.object.birth_date ?? "1990-01-01";
        this.object.zip =
          this.object.zip !== null && validation.is_zip(this.object.zip)
            ? this.object.zip
            : "000-0000";
        this.object.address = this.object.address ?? "不明";

        this.object.tax_type = 1;
        this.object.is_bank1 = 0;
        this.object.is_bank2 = 0;

        this.object.wage_type = 2;
        this.object.basepay = 0;
        this.object.carfare_type = 0;
        this.object.is_bonus = 0;
        this.object.over_type = 0;
        this.object.is_mid = 0;
        this.object.is_hol = 0;
        this.object.buy_paid_hol_unit = 0;

        this.object.health_ins_type = 0;
        this.object.pension_ins_type = 0;
        this.object.emp_ins_type = 0;
        this.object.is_comp_ins = 0;
      } else if (newval == 2) {
        //シフトと勤怠に変わった
        this.object.birth_date = this.object.birth_date ?? "1990-01-01";
        this.object.zip =
          this.object.zip !== null && validation.is_zip(this.object.zip)
            ? this.object.zip
            : "000-0000";
        this.object.address = this.object.address ?? "不明";

        this.object.tax_type = 1;
        this.object.is_bank1 = 0;
        this.object.is_bank2 = 0;

        this.object.wage_type = 2;
        this.object.basepay = 0;
        this.object.coretime_type = 0;
        this.object.carfare_type = 0;
        this.object.is_bonus = 0;
        this.object.buy_paid_hol_unit = 0;

        this.object.health_ins_type = 0;
        this.object.pension_ins_type = 0;
        this.object.emp_ins_type = 0;
        this.object.is_comp_ins = 0;
      } else if (newval == 3 || newval == 4) {
        this.object.coretime_type = 0;
      } else if (newval == 5) {
        //年末調整のみに変わった
        this.object.is_bank1 = 0;
        this.object.is_bank2 = 0;

        this.object.wage_type = 2;
        this.object.basepay = 0;
        this.object.work_type = 0;
        this.object.coretime_type = 0;
        this.object.carfare_type = 0;
        this.object.is_bonus = 0;
        this.object.over_type = 0;
        this.object.is_mid = 0;
        this.object.is_hol = 0;
        this.object.buy_paid_hol_unit = 0;

        this.object.health_ins_type = 0;
        this.object.pension_ins_type = 0;
        this.object.emp_ins_type = 0;
        this.object.is_comp_ins = 0;

        //年末調整のみの場合、入社年月日はnullに
        this.object.joined_on = null;
        this.object.contract_started_on = null;
      }
      this.object.use_paid_hol_type = 1;

      // if (newval != 5) {
      //   //年末調整のみから変更 => 入社年月日を再セット
      //   this.object.joined_on = this.object.joined_on ?? util.today();
      //   this.object.contract_started_on = this.object.contract_started_on ?? util.today();
      // }
    }

    onChangeHiringType() {
      //開いた時のみ適用
      if (this.object.hiring_type == 0 || this.object.hiring_type == 3) {
        //アルバイト・その他
        this.object.tax_type = 1;
        this.object.wage_type = 0;
        this.object.carfare_type = 1;
        this.object.is_bonus = 0;
        this.object.over_type = 1;
        this.object.is_preover = 1;
        this.object.is_lawover = 1;
        this.object.is_mid = 1;
        this.object.is_hol = 1;
        this.object.is_outhol = 1;
        this.object.is_lawhol = 1;
        this.object.health_ins_type = 0;
        this.object.pension_ins_type = 0;
        this.object.emp_ins_type = 0;
        this.object.is_comp_ins = 1;
        this.object.week_prescribed_day = 2;
        this.object.week_prescribed_hour = 480;
      } else if (this.object.hiring_type == 1) {
        //社員
        this.object.tax_type = 0;
        this.object.wage_type = 1;
        this.object.carfare_type = 2;
        this.object.is_bonus = 1;
        this.object.over_type = 1;
        this.object.is_preover = 1;
        this.object.is_lawover = 1;
        this.object.is_mid = 1;
        this.object.is_hol = 1;
        this.object.is_outhol = 1;
        this.object.is_lawhol = 1;
        this.object.health_ins_type = 1;
        this.object.pension_ins_type = 1;
        this.object.emp_ins_type = 1;
        this.object.is_comp_ins = 1;
        this.object.week_prescribed_day = 5;
        this.object.week_prescribed_hour = 2400;
      } else if (this.object.hiring_type == 2) {
        //役員
        this.object.tax_type = 0;
        this.object.wage_type = 1;
        this.object.carfare_type = 2;
        this.object.is_bonus = 0;
        this.object.over_type = 0;
        this.object.is_mid = 0;
        this.object.is_hol = 0;
        this.object.health_ins_type = 1;
        this.object.pension_ins_type = 1;
        this.object.emp_ins_type = 1;
        this.object.is_comp_ins = 1;
        this.object.week_prescribed_day = null;
        this.object.week_prescribed_hour = null;
      }
    }

    get object() {
      return this.m.edit_manager.add.object.employee;
    }

    @Provide("is_edit")
    get is_edit() {
      return !!this.m.edit_manager.add.flag;
    }

    @Provide("employee_type")
    get employee_type() {
      this.onChangeEmployeeType(this.object.type);
      return this.object.type;
    }

    update() {
      this.m.create({
        name: "add",
        args: {
          employee_id: 0,
          employee: this.m.edit_manager.add.object.employee,
          is_branchly_carfare: this.global.config.is_branchly_carfare,
        },
        self: this,
      });
    }
    cancel() {
      dialog.openForceConfirmDialog({
        title: "確認",
        msg: "追加をキャンセルしてよろしいですか？",
        detail: "入力した内容は全て破棄されます。",
        func: this.cancelProcess,
        args: {},
        exec_button: "変更を破棄",
        cancel_button: "入力を続ける",
      });
    }
    cancelProcess() {
      this.$router.push({ name: "employee", query: { ...this.$route.query } });
    }
  }
